import React, { Fragment } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
// import ProcessArrow from "elements/ProcessArrow"
import MediaBox from "elements/MediaBox"

import useMentalHealthImages from "./hooks/useMentalHealthImages"
import howProgramWorks from "./utils/howProgramWorks.json"

const HomeHowProgramWorks = () => {
  const data = useMentalHealthImages()

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 has-text-centered has-text-primary">
          Here's how it works
        </h3>
        <div className="columns is-centered mb-3">
          {howProgramWorks.map((item, index) => (
            <Fragment>
              <MediaBox
                title={
                  <h3 className="has-text-primary">{item?.title}</h3>
                }
                image={{
                  ...data[item?.imageName]?.childImageSharp?.fixed,
                  alt: item.imageName,
                }}
              >
                <p>{item?.description}</p>
              </MediaBox>
              {/* {index < howProgramWorks?.length - 1 && (
                <ProcessArrow arrowImage={data.arrow} />
              )} */}
            </Fragment>
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeHowProgramWorks
