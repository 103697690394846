import React, { useContext } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import HomeHowProgramWorks from "./HomeHowProgramWorks"

import useMentalHealthImages from "./hooks/useMentalHealthImages"
import { hasRoles, hasSignedInUser } from "../../Auth/services/user"
import CompleteProfileModal from "./CompleteProfileModal"
import { AppContext } from "../../../context/AppContext"

const MentalHealth = (props) => {
  const { pageContext } = props
  const { module } = pageContext
  const data = useMentalHealthImages()
  const heart = data.heart.childImageSharp.fluid.src

  const { dispatch } = useContext(AppContext)

  const handleClick = async () => {
    let hasUser = await hasSignedInUser()
    if (hasUser) navigate("/verify-email")
    else if (hasUser && !hasRoles())
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Complete your profile",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
          content: <CompleteProfileModal />,
        },
      })
    else navigate(pageContext.nextPath)
  }

  return (
    <Layout
      pageContext={pageContext}
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Teleconsult | Physical Health"
    >
      <Hero
        size="medium"
        background={{
          image: heart,
          position: "center",
        }}
      >
        <Container isCentered desktop={10} fullhd={8}>
          <Container mobile={12} tablet={8} desktop={8} padding={0}>
            <h1 className="title has-text-primary">
              {module?.title}
            </h1>

            <Button color="primary" size="medium" onClick={handleClick}>
              Request Teleconsult
            </Button>
          </Container>
        </Container>
      </Hero>
      <HomeHowProgramWorks />
    </Layout>
  )
}

export default MentalHealth
