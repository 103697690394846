import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * A box section used in colums
 * @param {children}
 * @param {string} title string
 * @param {object} image settings for the image (src, alt)
 */

const MediaBox = ({ image, title, children }) => {
  return (
    <div
      className={classNames(
        "column is-3-fullhd is-3-desktop has-text-centered",
        styles["mediaBox"]
      )}
    >
      <div className="mediaBox-wrapper">
        <div className="mediaBox__imageContainer mediaBox-item mt-3">
          <img
            src={image?.src}
            alt={`${image?.alt}`}
            className={classNames(styles["mediaBox__image"])}
            width={image?.width || "50%"}
          />
        </div>
        <div className="mediaBox-item">
          <h3 className={classNames("no-margin-top", styles["mediaBox__title"])}>{title}</h3>
          <p className="has-text-justified">{children}</p>
        </div>
      </div>
    </div>
  )
}

export default MediaBox
