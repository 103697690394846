import { useStaticQuery, graphql } from "gatsby"

const useMentalHealthImages = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      book: file(relativePath: { eq: "icons/mh__1_book.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      confirm: file(relativePath: { eq: "icons/mh__2_confirm.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      attend: file(relativePath: { eq: "icons/mh__3_attend.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      arrow: file(relativePath: { eq: "icons/triangle__grey.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // const heart = data.heart.childImageSharp.fluid.src
  // const arrow = data.arrow.childImageSharp.fixed
  // const book = data.arrow.childImageSharp.fixed
  // const confirm = data.arrow.childImageSharp.fixed
  // const attend = data.arrow.childImageSharp.fixed

  return data
}

export default useMentalHealthImages
